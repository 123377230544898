.row {
    --bs-gutter-x: 0rem
}

.subtitle {
    color: #eee;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.input-container {
    height: 50px;
    position: relative;
    width: 100%;
}

.ic1 {
    margin-top: 20px;
}

.ic2 {
    margin-top: 30px;
}

.cut {
    background-color: #ffffff;
    height: 20px;
    left: 20px;
    position: absolute;
    top: 2px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 18%;
}

.cut2 {
    background-color: #ffffff;
    height: 20px;
    left: 20px;
    position: absolute;
    top: 2px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 25%;
}

.cut-short {
    width: 50px;
}

.input:focus~.cut,
.input:not(:placeholder-shown)~.cut {
    transform: translateY(-10px);
}

.input:focus~.cut2,
.input:not(:placeholder-shown)~.cut2 {
    transform: translateY(-10px);
}

.placeholder {
    color: #000000;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 12px;
    background-color: transparent;
}

.input:focus~.placeholder,
.input:not(:placeholder-shown)~.placeholder {
    transform: translateY(-20px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown)~.placeholder {
    color: #808097;
}

.input:focus~.placeholder {
    color: #3B86FF;
}

.input:focus {
    border: solid 2px #3B86FF;
    box-shadow: none
}

.alert {
    right: 0px;
    position: absolute;
    top: -10px;
}