:root {
  --white-color:                  #FFFFFF;
  --primary-color:                #247cff;
  --section-bg-color:             #DBF4FF;
  --dark-color:                   #000000;
  --title-color:                  #565758;
  --p-color:                      #444444;
  --border-color:                 #eaeaea;

  --body-font-family:            'Montserrat', sans-serif;

  --h1-font-size:                 48px;
  --h2-font-size:                 36px;
  --h3-font-size:                 32px;
  --h4-font-size:                 28px;
  --h5-font-size:                 24px;
  --h6-font-size:                 18px;
  --p-font-size:                  12px;
  --menu-font-size:               14px;

  --font-weight-light:            300;
  --font-weight-normal:           400;
  --font-weight-semibold:         600;
  --font-weight-bold:             700;
}
body {
    background:#ffffff;
    font-family:'Montserrat', sans-serif ;    
    position: relative; 
	font-size: 14px;
     background-color:#F6FAFC;
}

/*---------------------------------------
  TYPOGRAPHY               
-----------------------------------------*/

h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1,
h2 {
  font-weight: 600;
}

h1 {
  font-size: 48px;
  line-height: normal;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 18px;
}
.appointment{font-size: 16px}
p {
  color: #444444;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

a, 
button {
  touch-action: manipulation;
  transition: all 0.3s;
}

a {
  color: #444444;
  text-decoration: none;
}

a:hover {
  color: #247cff;
}

.section-padding {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.ml-4, .mx-4 {
    margin-left: 1.5rem !important;
}

.mr-4, .mx-4 {
    margin-left: 1.5rem !important;
}
.m-0 {
    margin: 0rem !important;
}
.btn-outline-warning{color:#DF8E2E;}
.btn-outline-warning:hover{color:#ffffff; background-color: #DF8E2E }
/*---------------------------------------
  CUSTOM LINK               
-----------------------------------------*/
.custom-link {
  display: inline-block;
  text-decoration: none;
  border: 1px solid var(--border-color);
  color: #247cff;
  pointer-events: auto;
  font-weight: var(--font-weight-semibold);
  line-height: 40px;
  position: relative;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  border-radius: 0;
  text-transform: uppercase;
}
.custom-link::before {
  content: attr(data-hover);
  background-color: var(--border-color);
  color: var(--primary-color);
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
  right: 0;
  text-align: center;
}

.custom-link:hover::before {
  top: 0;
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

/*---------------------------------------
  LIST GROUP               
-----------------------------------------*/
.list-group-item {
  background-color: transparent;
  color: var(--p-color);
  font-size: var(--p-font-size);
  padding: 1rem 0;
}

.list-group-item:first-child {
  padding-top: 0;
}

.list-group-item span {
  font-weight: var(--font-weight-semibold);
  margin-left: auto;
}


/*---------------------------------------
  ANIMATED TEXT              
-----------------------------------------*/
.animated {
  position: relative;
}

.animated-info {
  display: inline-block;
  vertical-align: top;
  min-width: 250px;
  position: relative;
}

.animated-item {
  color: var(--primary-color);
}

.animated-item {
  display: block;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  animation: BottomTotop 6s linear infinite 0s;
}

.animated-item:nth-child(2n+2) {
  animation-delay: 2s;
}

.animated-item:nth-child(3n+3) {
  animation-delay: 4s;
}

@keyframes BottomTotop {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(5px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(5px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/*---------------------------------------
  HERO              
-----------------------------------------*/
.hero {  
  padding-top: 8rem !important;
	background-color: #2488B3;
	 height: 550px;
}

.hero-sub {  
  padding-top: 8rem !important;
	background-color: #2488B3
}

.hero .container {
  position: relative;
  overflow: hidden;
  height: 480px;
}
.hero-sub .container {
  position: relative;
  overflow: hidden;
  
}

.heroText {
  background: var(--white-color);
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  padding: 1.5rem 2.5rem;
  width: 50%;
}

.contact-phone {
  font-weight: var(--font-weight-semibold);
}

/*---------------------------------------
  FEATURED              
-----------------------------------------*/
.featured-circle {
  border-radius: 100%;
  width: 350px;
  height: 350px;
  margin: 0 auto;
  padding: 0 20px;
}

.featured-text {
  font-size: var(--h6-font-size);
  line-height: 2rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
  margin-bottom: 0;
}

.featured-number {
  color: var(--primary-color);
  font-size: 8rem;
  margin: 0 10px;
}
.threedots:after {
  content: "\2807";
  font-size: 15px;
}
.react-tel-input .form-control {
  background: #fff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  font-size: 14px;
  height: 35px;
  letter-spacing: .01rem;
  line-height: 25px;
  margin-bottom: 0 !important;
  margin-left: 0;
  margin-top: 0 !important;
  outline: none;
  padding-left: 48px;
  position: relative;
  width:100% !important
}
/*---------------------------------------
  NAVIGATION              
-----------------------------------------*/


.navbar-brand {
  color: var(--primary-color);
  font-size: var(--h5-font-size);
  font-weight: var(--font-weight-bold);
  padding: 0;
 
}

.navbar-brand strong {
  color: var(--p-color);
  font-size: 12px;
  position: relative;
  bottom: 5px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-nav .nav-link {
  color: var(--p-color);
  font-weight: var(--font-weight-semibold);
  font-size: var(--menu-font-size);
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-nav .nav-item.active .nav-link, 
.nav-link:focus, 
.nav-link:hover {
  color: var(--dark-color);
}

.nav-link:focus {
  color: var(--p-color);
}

.navbar-toggler {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  width: 30px;
  height: 35px;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background: transparent;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before,
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease, -webkit-transform 300ms 350ms ease;
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
}

.navbar-toggler .navbar-toggler-icon {
  background: var(--dark-color);
  transition: background 10ms 300ms ease;
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
}

.navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease, -webkit-transform 300ms 50ms ease;
  position: absolute;
  right: 0;
  left: 0;
  background: var(--dark-color);
  width: 30px;
  height: 2px;
  content: '';
}

.navbar-toggler .navbar-toggler-icon:before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon:after {
  top: 8px;
}

/*---------------------------------------
  TIMELINE               
-----------------------------------------*/
.timeline,
.timeline-nodes {
  position: relative;
}

.timeline-nodes:nth-child(even) {
  flex-direction: row-reverse;
}

.timeline h3, 
.timeline p {
  padding: 10px 30px;
} 

.timeline h3 {
  background: var(--dark-color);
}

.timeline::before {
  content: "";
  display: block;
  position: absolute;
  top: 37px;
  left: 50%;
  width: 0;
  border-left: 1px solid var(--border-color);
  height: 85%;
  z-index: 1;
  transform: translateX(-50%);
}

.timeline-content {
  position: relative;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0;
}

.timeline-nodes:nth-child(odd) h3,
.timeline-nodes:nth-child(odd) p {
  text-align: right;
}

.timeline-nodes:nth-child(odd) .timeline-date {
  text-align: left;
}
 
.timeline-nodes:nth-child(even) .timeline-date {
  text-align: right;
}

.timeline-nodes:nth-child(odd) h3::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  width: 0;
  border-left: 10px solid var(--dark-color);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.timeline-nodes h3 {
  position: relative;
  border-radius: 0.25rem 0.25rem 0 0;
}

.timeline-nodes:nth-child(even) h3::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
  width: 0;
  border-right: 10px solid var(--dark-color);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.timeline-icons {
  position: relative;
  z-index: 100;
}

.timeline-icons::before {
  content: "";
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
  z-index: 1;
}

.timeline-icon {
  position: relative;
  z-index: 100;
  font-size: var(--h3-font-size);
  color: var(--primary-color);
  display: block;
  text-align: center;
  line-height: 80px;
}

@media (max-width: 767px) {
  .timeline-nodes:nth-child(odd) h3,
  .timeline-nodes:nth-child(odd) p {
    text-align: left;
  }

  .timeline-nodes:nth-child(even) {
    flex-direction: row;
  }

  .timeline::before {
    content: "";
    display: block;
    position: absolute;
    top: 60px;
    left: 33px;
    width: 0;
    height: 90%;
    z-index: 1;
    transform: translateX(-50%);
  }

  .timeline-icons {
    position: absolute;
    left: 0%;
    top: 60px;
  }

  .timeline-nodes:nth-child(odd) h3::after {
    left: auto;
    right: 100%;
    border-left: 0;
    border-right: 10px solid var(--dark-color);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .timeline-nodes:nth-child(even) h3::after {
    right: 100%;
    width: 0;
    border-right: 10px solid var(--dark-color);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .timeline-nodes:nth-child(even) .timeline-date {
    text-align: left;
  }

  .timeline-icons::before {
    width: 75px;
    height: 75px;
  }

  .timeline-icon {
    line-height: 75px;
  }
}

@media (max-width: 575px) {
  .timeline::before {
    content: "";
    display: block;
    top: 60px;
    left: 57px;
    height: 87%;
  }

  .timeline-icons {
    position: absolute;
    left: -10px;
  }

  .timeline-icons::before {
    width: 70px;
    height: 70px;
  }

  .timeline-icon {
    line-height: 70px;
  }
}


/*---------------------------------------
  REVIEWS               
-----------------------------------------*/
.reviews-thumb {
  margin-bottom: 0;
  padding: 32px;
}

.reviews-text {
  margin-top: 10px;
  margin-bottom: 25px;
}

.reviews-image {
  border-radius: 100px;
  width: 70px !important;
  height: 70px !important;
}

.reviews-carousel .owl-item {
  opacity: 0.45;
}

.reviews-carousel .owl-item.active.center {
  opacity: 1;
}

.reviews-carousel .owl-item.active.center .reviews-thumb {
  background: var(--dark-color);
}

.reviews-carousel .owl-item.active.center .reviews-thumb,
.reviews-carousel .owl-item.active.center .reviews-text {
  color: var(--white-color);
}

.reviews-carousel .owl-nav {
  display: flex;
  justify-content: space-between;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reviews-carousel .owl-nav span {
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: block;
}

.reviews-carousel .owl-nav .owl-prev {
  position: relative;
  left: -80px;
}

.reviews-carousel .owl-nav .owl-next {
  position: relative;
  right: -80px;
}

.reviews-carousel .owl-prev span,
.reviews-carousel .owl-next span {
  color: transparent;
}

.reviews-carousel .owl-prev span::before,
.reviews-carousel .owl-next span::before {
  font-family: bootstrap-icons;
  display: block;
  font-size: var(--h3-font-size);
  color: var(--p-color);
  width: 35px;
  height: 35px;
}

.reviews-carousel .owl-prev span::before {
  content: "\f12f";
}

.reviews-carousel .owl-next span::before {
  content: "\f138";
}

/*---------------------------------------
  BOOKING FORM            
-----------------------------------------*/

.booking-form .form-control {
  background: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--border-color);
  color: #6c757d;
  font-weight: var(--font-weight-normal);
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 15px;
  transition: all 0.3s;
}

.booking-form #submit-button {
  background: var(--dark-color);
  border-bottom: 0;
  font-weight: var(--font-weight-semibold);
  color: var(--white-color);
  text-transform: uppercase;
  margin-top: 35px;
}

.booking-form #submit-button:hover {
  background: var(--primary-color);
}

/*---------------------------------------
  FOOTER              
-----------------------------------------*/
.site-footer {
  background: #DBF4FF;
}

.copyright-text {
  font-size: 12px;
}

/*---------------------------------------
  SOCIAL ICON               
-----------------------------------------*/
.social-icon {
  margin: 0;
  padding: 0;
}

.social-icon li {
  list-style: none;
  display: inline-block;
  vertical-align: top;
  transition: all 0.3s;
}

.social-icon:hover li:not(:hover) {
  opacity: 0.65;
}

.social-icon-link {
  font-size: var(--p-font-size);
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 15px;
}

.social-icon-link:hover {
  color: var(--primary-color);
}

/*---------------------------------------
  RESPONSIVE STYLES               
-----------------------------------------*/
@media screen and (min-width: 1600px) {
  .hero .container {
    height: 740px;
  }
}

@media screen and (max-width: 991px) {
  
  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .section-padding {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .navbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-nav .nav-link {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .hero .container {
    height: inherit;
  }

  .heroText {
    position: relative;
    bottom: 100px;
    left: 0;
    width: auto;
    margin-bottom: -100px;
    padding: 2rem;
  }

  .featured-circle {
    width: 320px;
    height: 320px;
  }
}

.slide-text{ padding-top: 6rem}
@media screen and (max-width: 767px) {
.slide-text{ padding-top: 0rem}	
  .heroText {
    padding: 1.5rem;
  }

  .animated-info {
    min-width: 125px;
  }

  .featured-circle {
    margin-top: 20px;
  }

  .reviews-carousel .owl-nav {
    position: relative;
    width: auto !important;
    transform: inherit;
    top: 0;
    bottom: 0;
    left: 0;
    max-width: 100px;
    margin: 20px auto;
  }

  .reviews-carousel .owl-nav .owl-prev,
  .reviews-carousel .owl-nav .owl-next {
    right: 0;
    left: 0;
  }
}

@media screen and (max-width: 480px) {
  .heroText {
    padding: 1rem;
  }

  .heroLinks .custom-link {
    font-size: 12px;
    line-height: 30px;
    margin-right: 12px !important;
    padding: 0 25px;
  }

  .contact-phone {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  .featured-number {
    font-size: 5rem;
  }

  .featured-circle {
    width: 235px;
    height: 235px;
  }

  .reviews-image {
    width: 55px !important;
    height: 55px !important;
  }

  .reviews-thumb figcaption {
    font-size: var(--menu-font-size);
  }

  .timeline::before {
    left: 42px;
  }
}


.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #2790BD;
}
.navbar-light .navbar-nav .nav-link {
    color: #7B7B7B;
}

.insurance-box{ background-color:#ECF1FD; border:10px #f8f9fa solid}

@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 95%;
}
	}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #000000;
    border-bottom: 5px solid #7DA53F;
	background-color: transparent!important; 
	border-radius: 0px;
}

.text-end {
    text-align:inherit !important;
}

.invalid-feedback{
  font-size: 11px;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
  display: block;
}




#shareBtn {
    letter-spacing: 2px;
    font-weight: 600;
    box-shadow: none;
    background-color: #fff;
    color: #7d2ae8;
    border: none
}

.btn-close {
    box-shadow: none;
    border: none;
    outline: none
}

.modal-body .icons {
    margin: 15px 0px 20px 0px
}

.modal-body .icons a {
    text-decoration: none;
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    transition: all 0.3s ease-in-out
}

.modal-body .icons a:nth-child(1) {
    color: #1877F2;
    border-color: #B7D4FB
}

.modal-body .icons a:nth-child(1):hover {
    background-color: #1877F2;
    color: #fff
}

.modal-body .icons a:nth-child(2) {
    color: #46C1F6;
    border-color: #b6e7fc
}

.modal-body .icons a:nth-child(2):hover {
    background-color: #46C1F6;
    color: #fff
}

.modal-body .icons a:nth-child(3) {
    color: #0a66c2;
    border-color: #0a66c2
}

.modal-body .icons a:nth-child(3):hover {
    background-color: #0a66c2;
    color: #fff
}

.modal-body .icons a:nth-child(4) {
    color: #25d366;
    border-color: #bef4d2
}

.modal-body .icons a:nth-child(4):hover {
    background-color: #25d366;
    color: #fff
}

.modal-body .icons a:nth-child(5) {
    color: #0088cc;
    border-color: #b3e6ff
}

.modal-body .icons a:nth-child(5):hover {
    background-color: #0088cc;
    color: #fff
}

.modal-body .icons a:hover {
    border-color: transparent
}

.modal-body .icons a span {
    transition: all 0.09s ease-in-out
}

.modal-body .icons a:hover span {
    transform: scaleX(1.1)
}

.modal-body .field {
    margin: 15px 0px -5px 0px;
    height: 45px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 0 5px
}

.modal-body .field.active {
    border-color: #7d2ae8
}

.field span {
    width: 50px;
    font-size: 1.1rem
}

.field.active span {
    color: #7d2ae8
}

.field input {
    border: none;
    outline: none;
    font-size: 0.89rem;
    width: 100%;
    height: 100%
}

.field button {
    padding: 5px 16px;
    color: #fff;
    background: #7d2ae8;
    border: 2px solid transparent;
    border-radius: 5px;
    font-weight: 500
}

@media (max-width: 330px) {
    .modal-body .icons a {
        margin-right: 15px;
        width: 35px;
        height: 35px
    }
}


/* Styles for verification */
#pswd_info {
	position:absolute;
	bottom:-180px;
	bottom: -115px\9;
	right:10px;
	width:320px;
	padding:15px;
	background:#fefefe; 
	font-size:.725em;
	border-radius:5px;
	box-shadow:0 1px 3px #ccc;
	border:1px solid #ddd;
	display:none; z-index: 9999;
}

#pswd_info ul{ margin: 0px; padding: 0px; list-style: none}
#pswd_info::before {
	content: "\25B2";
	position:absolute;
	top:-12px;
	left:45%;
	font-size:12px;
	line-height:14px;
	color:#ddd;
	text-shadow:none;
	display:block;
}
#pswd_info h4 {
	margin:0 0 10px 0; 
	padding:0;
	font-weight:bold; 
	font-size: 14px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}
.invalid {
	background:url("../images/invalid.png") no-repeat 0 50%;
	padding-left:25px;
	line-height:24px;
	color:#ec3f41;
}
.valid {
	background:url("../images/valid.png") no-repeat 0 50%;
	padding-left:25px;
	line-height:24px;
	color:#3a7d34;
}
.text-right {
  text-align: right !important;
}